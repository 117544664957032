import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import cover from "../../assets/img/salle-de-bain/cover.png"
import img1 from "../../assets/img/salle-de-bain/img-1.jpg"
import img2 from "../../assets/img/salle-de-bain/img-2.jpg"
import img3 from "../../assets/img/salle-de-bain/img-3.jpg"
import img4 from "../../assets/img/salle-de-bain/img-4.jpg"
import img5 from "../../assets/img/salle-de-bain/img-5.jpg"
import img6 from "../../assets/img/salle-de-bain/img-6.jpg"
import img7 from "../../assets/img/salle-de-bain/img-7.jpg"
import catalogue from "../../assets/img/salle-de-bain/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-salle-de-bain.pdf"


const SalleDeBainPage = ({ data }) => {
    
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
    const g1 = data.gallery1.edges.map(({ node }) => node.childImageSharp) 
    const g2 = data.gallery2.edges.map(({ node }) => node.childImageSharp) 
    return (
      <Layout>
        <SEO title="salle de bain" />
        <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
      <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
        <h1 class="text-center text-2xl font-bold uppercase">SALLE DE BAIN</h1>
        <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
      </div>
      <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
      <img src={cover} alt="cover" />
      </div>
      <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
        <p class="font-bold">SOLID SURFACE DANS LA SALLE DE BAINS !</p>
        <div class="border-solid border-2 border-red-500 w-12 mb-3"></div>
        </div>
      <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
          <div>  <img src={img1} alt="1" /> </div>
          <div class="col-1 md:col-span-2"> 
          <p class="mb-4">Votre salle de bains doit être le miroir de votre personnalité.</p>

            <p class="mb-4">Avec Solid Surface, vous pouvez faire en sorte que la pièce la plus intime de la maison soit votre reflet. Entourez-vous de couleurs et de textures auxquelles vous aimez éveiller vos sens. Moulez Solid Surface pour en adoucir les formes.</p>

            Mariez-le à la céramique, au métal, au bois, à la pierre ou au verre. Solid Surface est tellement facile à vivre que votre salle de bains sera aussi le reflet de votre sagesse.
          </div>
      </div>
      <div class="grid grid-cols-1 mt-8 md:mx-40 md:my-5 mx-3">
        <p class="mb-3 font-bold">La collection des lavabos en Solid Surface</p>
        <p>Enfin un lavabo dont vous ne vous lasserez pas. Une vasque en Solid Surface, quelle que soit sa forme ou sa taille, est une invite à la caresse, un régal pour les yeux, un délice à vivre au quotidien. Et lorsqu’elle est intégrée à un plan de toilette en Solid Surface, il n’y a plus ni rebords, ni raccords ouverts, ni joints de mastic susceptibles de retenir la poussière,  les microbes et les moisissures dans votre salle de bains.</p>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
            <div><img src={img2} alt="image-2" /></div>
            <div><img src={img3} alt="image-3" /></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
          <div>
          <p class="mb-3">Dans la salle de bain, découvrez les nombreuses possibilités que vous offre Dupont™ :</p>
          <ul>
            <li>– Baignoires sur-mesure</li>
            <li>– Douche</li>
            <li>– Plan de vasque</li>
            <li>– Mobilier sur mesure entièrement réalisé en Solide Surface</li>
            <li> – Habillages muraux</li>
          </ul>
          </div>
          <div><img src={img4} alt="image-4" /></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
          <div><img src={img5} alt="image-5" /></div>
          <div>
          <p class="font-bold text-xl mb-2">Le solid surface, un matériau idéal pour la salle de bain</p>
          <div class="border-solid border-2 border-red-500 w-12 mb-8"></div>
          <p class="mb-5">Si vous recherchez un matériau à la fois décoratif et adapté à une pièce d’eau, faites d’une pierre deux coups en misant sur le solid surface.</p>

          <p>Également appelé surface solide, matière composite ou fonte minérale, ce matériau de revêtement massif haut de gamme est issu du mélange de minéraux naturels et de résines acrylique.</p>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
          <div>
            <p class="mb-4">Dans une salle de bain, le solid surface peut se retrouver sous forme de lavabo, de plan vasque, de baignoire ou encore de receveur de douche.</p>

            <p class="mb-4">Moderne et contemporain, classique ou zen, quel que soit votre style de décoration, ce matériau sera parfait.</p>

            <p class="mb-4">Malléable et facile à manipuler, cette matière composite offre des possibilités de transformation et de création quasiment infinies.</p>

            <p>Se travaillant comme le bois et pouvant être thermoformé, elle peut être utilisée pour produire des pièces courbées et offre un grand potentiel de design (forme arrondie, rectangulaire, ovale…).</p>
          </div>
          <div><img src={img6} alt="image-6" /></div>
        </div>
        <div class="grid grid-cols-1 flex items-center md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
          <div><img src={img7} alt="image-7" /></div>
          <div> 
            <p class="mb-4">Pourquoi choisir le solid surface pour votre salle d’eau ? Offrant une surface non poreuse et chaude, le solid surface est agréablement doux et lisse au toucher, conférant une sensation agréable lors de son utilisation.</p>

            <p class="mb-4">Hygiénique, il se démarque par sa résistance contre les impuretés et le développement de moisissures.</p>

            <p class="mb-4">Durable et résilient, le solid surface est un matériau robuste et résistant aux taches.</p>

            <p>Sa capacité à garder ses couleurs stables au fil du temps ainsi que sa facilité d’entretien et de réparation figurent aussi parmi les avantages de ce matériau.</p>
          </div>
        </div>
      <div class="grid grid-cols-1  mt-8 gap-8 md:mx-40 md:my-5 mx-3">
                <h3 class="font-bold  my-3">Il ne vous reste plus qu’à laisser libre cours à votre imagination pour créer la salle de bain de vos rêves en solide surface !</h3>         
      </div>
      <div class="grid grid-cols-1 gl gl33 mt-8 md:mx-40 md:my-5 mx-3">
        <Gallery images={g1} />
      </div>
      <div class="grid grid-cols-1 mt-8 gap-8 md:grid-cols-3 md:mx-40 md:my-5 mx-3 gl gl33">
          <div class="col-1 md:col-span-2"><Gallery images={g2} /></div>
            <div>
                <a href={catalogueFile} class="relative block hover-trigger" download>
                <img src={catalogue} alt="cataloge cuisine" class="my-0" /> 
                <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
                    Télécharger
                </div>
                </a>
            </div>
        </div>
      </Layout> 
)
}
export const query = graphql`
query SalleGallery {
  gallery1:allFile (filter:{relativeDirectory: {eq: "img/salle-de-bain/g1"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  gallery2:allFile (filter:{relativeDirectory: {eq: "img/salle-de-bain/g2"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  sld:allFile (filter:{relativeDirectory: {eq: "img/salle-de-bain/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default SalleDeBainPage